// 解决方案 -> 详细方案
<template>
  <div class="plan-detail-wrapper">
    <div class="plan-bg-wrapper">
      <div class="plan-bg wrap-v1">
        <div class="title ">{{ details.title }}</div>
      </div>
    </div>

    <div class="wrap-v1 plan-detail">
      <img class="plan-img" :src="details.bgUrl" alt="" />
      <div class="title">方案简介</div>
      <div class="sub-title">
        {{ details.desc }}
      </div>
      <div class="title">应用场景</div>
      <div class="yycj">
        <div
          class="item"
          v-for="(item, index) in details.usedList"
          :key="index"
        >
          <img :src="item.imgUrl" alt="" />
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
      <div class="title">方案特点</div>
      <img :src="details.characteristic" alt="" class="fatd" />

      <div class="title">方案详情</div>
      <imgLeftorright
        :desc="details.fadetail.desc"
        :imgUrl="details.fadetail.imgUrl"
      />

      <Advantage
        class="mt-8"
        title="特点优势"
        :advantage="details.advantage"
      ></Advantage>
      <Partner :partner="details.partner" class="mt-12"></Partner>
    </div>
  </div>
</template>
<script>
import imgLeftorright from "@/components/img-leftorright";
import Advantage from "@/components/advantage.vue";
import Partner from "@/components/partner.vue";
import solutionList from "./solutionList";
export default {
  components: { imgLeftorright, Advantage, Partner },
  name: "plan-detail",
  data() {
    return {
      details: {}
    };
  },
  created() {
    this.details = solutionList.filter(item => {
      if (item.id == this.$route.params.id) return item;
    })[0];
    // console.log("this.details", this.details);
  },
  mounted() {
    let page = document.querySelector("#app");
    if (page.scrollTop > 0) {
      page.scrollTop = 0;
    }
  }
};
</script>
<style scoped lang="scss">
.mt-8 {
  margin-top: 80px;
}
.mt-12 {
  margin-top: 120px;
}
.plan-detail-wrapper {
  .plan-bg-wrapper {
    height: 250px;
    background-color: #007bb9;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    .plan-bg {
      flex: 1;
      .title {
        font-size: 56px;
      }
    }
  }
  .plan-detail {
    padding: 80px 0 100px;
    .plan-img {
      width: 100%;
      max-width: 1200px;
      height: 450px;
      margin-bottom: 80px;
    }
    .sub-title {
      margin-top: 40px;
      margin-bottom: 80px;
      font-size: 20px;
      color: #333333;
      line-height: 40px;
      font-weight: 300px;
      text-align: left;
    }
    .title {
      font-size: 50px;
      color: #333333;
      line-height: 70px;
    }
    .yycj {
      margin-top: 60px;
      margin-bottom: 90px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        width: 387px;
        img {
          width: 387px;
          height: 250px;
        }
        .text {
          font-size: 26px;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          padding: 55px 0;
        }
      }
    }
    .fatd {
      margin-top: 60px;
      margin-bottom: 80px;
      width: 1200px;
    }
  }
}
</style>
